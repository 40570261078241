@import './../../../../scss/theme-bootstrap';

.product-replenishment-select {
  padding: 0 0 15px 0;
  font-size: 16px;
  &__options {
    display: none;
    padding: 10px 0;
    .product-full &,
    .product-quickshop & {
      @include breakpoint($medium-up) {
        width: 60%;
      }
    }
  }
  &__option {
    display: inline-block;
    white-space: nowrap;
    margin-#{$rdirection}: 8px;
    label {
      display: inline-block;
    }
    sup {
      margin-left: 6px;
      cursor: pointer;
      .product-quickshop__actions & {
        display: none;
      }
    }
  }
}
